.FSuper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    var(--bg-secondary),
    var(--text-main)
  );
  background-position: center;
  border-bottom: solid var(--text-main) 6px;
  padding-bottom: 5rem;
}

.Fcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
}

.FTitle {
  padding: 40px 0px;
}

.FTitle h1 {
  color: var(--text-focus);
  font-weight: bolder;
  font-size: 40px;
  text-align: left;
}

.WrapOpen {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(to right, var(--bg-secondary), var(--bg-main));
  padding: 0px 20px;
  /* cursor: pointer; */
  cursor: pointer;
  font-size: 11px;
  z-index: 10;
  transition: 0.3s ease-in-out;
  color: var(--text-focus);
  border-top: solid 3px var(--text-focus);
}

.WrapClosed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* background: linear-gradient(to right, var(--bg-main), var(--bg-secondary)); */
  background: var(--text-focus);
  padding: 0px 20px;
  /* cursor: pointer; */
  cursor: pointer;
  /* border-top: solid 2px var(--text-focus); */
  font-size: 9px;
  z-index: 10;
  transition: 0.3s ease-in-out;
  border-top: solid 2px transparent;
  border-bottom: solid 2px transparent;
  color: var(--bg-secondary);
}

.WrapClosed:hover {
  background: linear-gradient(to right, var(--bg-secondary), var(--bg-main));
  transition: 0.3s ease-in-out;
  color: var(--text-focus);
}

.ArrowOpen {
  transition: 0.3s ease-in-out;
  transform: rotate(45deg);
  height: 30px;
}

.ArrowClosed {
  transition: 0.3s ease-in-out;
  height: 30px;
}

.DropOpen {
  background: linear-gradient(to right, var(--bg-secondary), var(--bg-main));
  width: 100%;
  padding: 5px 20px;
  max-height: 500px;
  transition: 0.5s ease-in-out;
  font-size: 20px;
  /* cursor: pointer; */
  cursor: pointer;
  color: var(--text-focus);
  margin-bottom: 10px;
  border-bottom: solid 3px var(--text-focus);
}

.DropClosed {
  background: rgb(26, 26, 26);
  width: 100%;
  padding: 5px 20px;
  max-height: 0px;
  transition: 0.5s ease-in-out;
  opacity: 0;
  /* font-size: 0px; */
}

/*==============Media Queries===============*/

@media screen and (max-width: 1000px) {
  .Fcontainer {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .Wrap {
    font-size: 8px;
  }

  .FTitle h1 {
    font-size: 40px;
  }
}
