.A2Super {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-image: linear-gradient(
    to bottom right,
    var(--text-focus),
    var(--text-focus),
    #ffffff,
    #2535a9,
    #2535a9
  ); */
  /* background-color: var(--text-focus); */
  background-image: url("../../images/bg1.png");
  background-position: center;
  border-bottom: solid #ffffff 6px;
  margin-right: 150px;
}

.A2Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70%;
  padding: 100px 0%;
}

.A2Right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: justify;
  flex: 1;
}

.A2Right p {
  /* text-shadow: 0 0 5px rgb(255, 255, 255); */
  font-weight: bolder;
  font-size: 18px;
  color: white;
  text-shadow: 0 0 4px rgb(0, 0, 0), 0 0 4px rgb(0, 0, 0), 0 0 4px rgb(0, 0, 0);
}

.A2Right span {
  color: #ba1616;
  text-shadow: 0 0 4px rgb(255, 255, 255), 0 0 4px rgb(255, 255, 255),
    0 0 4px rgb(255, 255, 255);
}

.A2Right h1 {
  color: #2535a9;
  font-weight: bolder;
  font-size: 50px;
  text-align: left;
  margin-bottom: 0px;
  padding: 0px 0px;
  text-shadow: 0 0 4px rgb(255, 255, 255), 0 0 4px rgb(255, 255, 255),
    0 0 4px rgb(255, 255, 255);
}

.A2Right h2 {
  color: #2535a9;
  margin: 0px;
  padding: 0px 0px;
  /* line-height: 20px; */
  font-weight: bolder;
  font-size: 40px;
  text-align: left;
  text-shadow: 0 0 4px rgb(255, 255, 255), 0 0 4px rgb(255, 255, 255),
    0 0 4px rgb(255, 255, 255);
}

.H2Span span {
  color: rgb(181, 24, 24);
  text-shadow: 0 0 4px rgb(255, 255, 255), 0 0 4px rgb(255, 255, 255),
    0 0 4px rgb(255, 255, 255);
}

.A2Right img {
  width: 600px;
}

.DarkSquare {
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.A2Left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.A2Left img {
  height: 500px;
  animation: infinite ease-in-out 3s;
  animation-name: laugh;
}

@keyframes laugh {
  0% {
    width: 500px;
  }
  50% {
    width: 0px;
  }
  100% {
    width: 500px;
  }
}

/*============Media Queries=============*/

@media screen and (max-width: 1000px) {
  .A2Super {
    margin-right: 0px;
  }

  .A2Container {
    width: 90%;
  }
}

@media screen and (max-width: 850px) {
  .A2Container {
    flex-direction: column-reverse;
    padding: 30px 0%;
  }

  .A2Right h1 {
    font-size: 24px;
  }

  .A2Left img {
    /* width: 290px; */
    height: 290px;
    padding-bottom: 2rem;
    animation-name: laugh2;
  }
}

@keyframes laugh2 {
  0% {
    width: 290px;
  }
  50% {
    width: 0px;
  }
  100% {
    width: 290px;
  }
}
