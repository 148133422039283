.NavSuper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  width: 150px;
  height: 100vh;
  border-left: solid #ffffff 4px;
  /* color: wheat; */
  background: linear-gradient(to right, #22681a, #339a27);
  background-image: url("../../images/vflag.png");
  background-position: center;

  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
}

.NavContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 75%;
  height: 100%;

  /* border: 1px solid rgb(255, 255, 255); */
}

.Logo {
  /* border: 1px solid rgb(253, 2, 2); */
}

.Logo img {
  height: 150px;
  cursor: pointer;
}

.Links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;

  /* border: 1px solid rgb(47, 54, 255); */
}

.Links p {
  cursor: pointer;
  color: var(--text-main);
  transition: 0.3s ease-in-out;
  font-weight: bolder;
}

.Links p:hover {
  cursor: pointer;
  color: var(--text-focus);
  transition: 0.3s ease-in-out;
}

.NavLink {
  border-bottom: solid transparent 4px;
  transition: 0.3s ease-in-out;
}

.NavLink:hover {
  border-bottom: solid var(--text-focus) 4px;
}

.Socials {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 170px;

  /* border: 1px solid greenyellow; */
}

.Socials img {
  height: 40px;
  transition: 0.3s ease-in-out;
}

.Socials a {
  cursor: pointer;
}

.Socials img:hover {
  transition: 0.3s ease-in-out;
  transform: scale(1.1);
  /* filter: invert(50%) sepia(100%) hue-rotate(170deg) saturate(10); */
}

.NavButtons {
  display: flex;
  flex-direction: column;
}

.NavBuyBtn img {
  position: absolute;
  height: 50px;
  margin-right: 10px;
}

.NavBuyBtn p {
  font-size: 15px;
  z-index: 10;
  text-shadow: 0 0 4px rgb(255, 255, 255), 0 0 4px rgb(255, 255, 255),
    0 0 4px rgb(255, 255, 255);
  text-align: center;
}

.NavBuyBtnGray img {
  height: 25px;
  margin-right: 10px;
  filter: grayscale();
}

.NavBuyBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50px;
  height: 50px;
  background: white;
  border: solid var(--text-main) 4px;
  border-radius: 50px;
  padding: 10px 10px;
  margin-right: 10px;
  color: var(--text-focus);
  font-weight: bold;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  z-index: 30;
}

.NavBuyBtnGray {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: lightgrey;
  border: solid var(--text-main) 2px;
  border-radius: 2rem;
  padding: 10px 10px;
  margin-right: 10px;
  color: var(--text-main);
  font-weight: bold;
  cursor: pointer;
  transition: ease-in-out 0.3s;
}

.NavBuyBtn:hover {
  background: white;
  border: solid var(--text-focus) 4px;
  color: var(--text-main);
  transform: scale(1.1);
}

.NavAppBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  /* background: white; */
  border: solid goldenrod 4px;
  border-radius: 7px;
  padding: 10px 10px;
  margin-right: 10px;
  color: var(--text-main);
  font-weight: bold;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  animation: launchani 10s infinite ease-in-out;
}

@keyframes launchani {
  0% {
    /* border: solid black 4px; */
    /* color: var(--text-main); */
    background: white;
  }
  33% {
    /* border: solid black 4px; */
    /* color: var(--text-main); */
    background: var(--text-main);
  }
  66% {
    /* border: solid black 4px; */
    /* color: var(--text-focus); */
    background: var(--text-focus);
  }
  100% {
    /* border: solid black 4px; */
    /* color: var(--text-main); */
    background: white;
  }
}

.NavAppBtn img {
  position: absolute;
  height: 50px;
  margin-right: 10px;
}

.NavAppBtn p {
  font-size: 25px;
  z-index: 10;
  text-shadow: 0 0 4px rgb(255, 255, 255), 0 0 4px rgb(255, 255, 255),
    0 0 4px rgb(255, 255, 255);
  text-align: center;
}

.Movil {
  position: absolute;
  width: 0%;
  min-width: 0px;
  pointer-events: none;
  opacity: 1;
  right: 40px;

  /* border: 1px solid rgb(255, 47, 217); */
}

.Movil img {
  width: 0px;
}

.NavBtn {
  background: none;
  border: none;
}

/*=========== Media Queries ===========*/

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 850px) {
  .NavSuper {
    justify-content: left;
  }

  .NavContainer {
    /* width: calc(50% + 50px); */
    justify-content: space-between;
  }

  .Links {
    pointer-events: none;
    opacity: 0;
    width: 0%;
    min-width: 0px;
    font-size: 0px;

    /* border: 1px solid rgb(47, 54, 255); */
  }

  .Socials {
    pointer-events: auto;
    opacity: 1;
    width: 95px;
    min-width: 0px;

    /* border: 1px solid greenyellow; */
  }

  .Socials img {
    width: 0%;
    pointer-events: none;
  }

  /* .MintBtn a {
    opacity: 0;
    pointer-events: none;
  } */

  .Movil {
    position: absolute;
    width: 40px;
    pointer-events: auto;
    opacity: 1;
    right: 40px;

    /* border: 1px solid rgb(255, 47, 217); */
  }

  .Movil img {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 425px) {
  .NavButtons {
    flex-direction: column;
  }

  .NavBuyBtn {
    margin-bottom: 3px;
    margin-top: 3px;
  }

  .MintBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
  }

  .MintBtn p {
    font-size: 12px;
  }

  .UBtn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 90px;
  }

  .UBtn a {
    padding: 0px 0px;
    text-decoration: none;
  }

  .UBtn p {
    font-size: 12px;
    margin: 0%;
    padding: 0%;
  }
}

/*============Panel Movil============*/

.NavPanel-Closed {
  position: absolute;
  top: 60px;
  width: 95%;
  background: var(--text-focus);
  opacity: 0;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.NavPanel-Closed p {
  color: black;
}

.NavPanel-Open {
  position: absolute;
  top: 60px;
  width: 95%;
  background: var(--text-focus);
  opacity: 0;
  pointer-events: none;
  border: solid 2px var(--bg-secondary);
  border-radius: 10px;
  /* box-shadow: inset 0 0 40px aqua, 0 0 40px aqua; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 25% 0%;
}

.NavPanel-Open p {
  color: var(--bg-secondary);
}

.NavPanel-Open p:hover {
  color: white;
  text-shadow: 0 0 7px aqua, 0 0 10px aqua, 0 0 21px aqua, 0 0 42px aqua;
}

@media screen and (max-width: 850px) {
  .NavPanel-Closed {
    position: absolute;
    top: -60px;
    width: 95%;
    background: rgb(9, 53, 9);
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease-in-out;
  }

  .NavPanel-Open {
    opacity: 1;
    pointer-events: auto;
    transition: 0.3s ease-in-out;
  }
}

.PanelLinks {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0% 0%;
  padding-bottom: 10%;
}

.PanelSocials {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 150px;
}
