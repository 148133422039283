.FooterSuper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* background: rgb(20, 21, 92); */
  background: var(--text-focus);
  margin-right: 150px;
}

.FooterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 40px 0px;
}

.FooterLogo {
  animation: infinite linear 1s;
  animation-name: side;
}

@keyframes side {
  0% {
    rotate: 0deg;
  }
  25% {
    /* transform: scale(0.1); */
    rotate: 5deg;
  }
  75% {
    /* transform: scale(0.1); */
    rotate: -5deg;
  }
}

.FooterSocials {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  padding: 20px 0px;
  z-index: 40;
}

.FooterSocials a {
  cursor: pointer;
}

.FooterCR {
  font-size: 15px;
  color: rgb(255, 255, 255);
  text-align: center;
}

/*==============Media Queries===============*/
@media screen and (max-width: 1200px) {
  .FooterSuper {
    margin-right: 0px;
  }
}

@media screen and (max-width: 700px) {
  .FooterCR {
    font-size: 11px;
  }
}
