.A1Super {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-image: linear-gradient(
    to right,
    var(--bg-secondary),
    var(--text-main)
  ); */
  background-color: rgb(20, 21, 92);
  background-position: center;
  border-bottom: solid #ffffff 6px;
  margin-right: 150px;
}

.A1Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  padding: 50px 0%;
}

.A1Top {
  z-index: 30;
}

.A1Top h1 {
  color: #ffffff;
  font-size: 52px;
  text-shadow: 0 0 7px rgb(252, 27, 27), 0 0 15px rgb(246, 38, 38);
}

.A1Bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 50px 0%;
}

.A1Left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  flex: 1;
  color: black;
  z-index: 20;
}

.A1Left img {
  height: 800px;
}

.A1Left span {
  color: var(--bg-secondary);
}

.A1Left p {
  /* text-shadow: 0 0 5px rgb(255, 255, 255); */
  font-weight: bolder;
  color: white;
  text-shadow: 0 0 7px rgb(0, 0, 0), 0 0 15px rgb(0, 0, 0);
  /* font-family: "Exo", sans-serif; */
}

.A1Left ul {
  padding: 0px 5px;
  margin-top: 0px;
  list-style: none;
}

.A1Left ul li::before {
  content: "\2022";
  color: var(--text-focus);
  list-style-position: inside;
  padding: 0px 7px;
  margin-top: 0px;
}

.A1Left h1 {
  /* text-shadow: 0 0 7px rgb(0, 0, 0), 0 0 15px rgb(0, 0, 0); */
  color: var(--text-focus);
  font-weight: bolder;
  font-size: 40px;
  text-align: right;
}

.A1Right {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  z-index: 10;
}

.A1Right img {
  height: 1400px;
  animation: infinite linear 4s;
  animation-name: jumpy;
}

@keyframes jumpy {
  0% {
    opacity: 0;
    transform: translateY(0px) scale(0.1);
  }
  8% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.A1Table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
}

.A1Row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* height: 300px; */
}

.A1Cell {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: top;
  padding: 10px;
  height: 300px;
  width: 330px;
  border: solid white 4px;
}

.A1Cell img {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 150px;
}

.A1Cell h2 {
  color: blue;
  font-size: 28px;
  text-shadow: 0 0 4px rgb(255, 255, 255), 0 0 4px rgb(255, 255, 255),
    0 0 4px rgb(255, 255, 255);
}

.A1Cell p {
  z-index: 20;
  text-shadow: 0 0 4px rgb(0, 0, 0), 0 0 6px rgb(0, 0, 0), 0 0 8px rgb(0, 0, 0);
}

.A1Blue {
  background: #4b4b76d5;
}

.A1Red {
  background: #b22234d5;
}

/*============Media Queries=============*/

@media screen and (max-width: 1000px) {
  .A1Super {
    margin-right: 0px;
  }

  .A1Container {
    width: 90%;
  }
}

@media screen and (max-width: 850px) {
  .A1Container {
    flex-direction: column;
    padding: 30px 0%;
  }

  .A1Left h1 {
    font-size: 24px;
  }

  .A1Right img {
    width: 100%;
    height: auto;
  }

  .A1Left img {
    width: 100%;
    height: auto;
  }

  .A1Bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding: 50px 0%;
  }
}
